/* Google chrome */

.path {
    animation: svg-text-anim 1.3s linear 0.3s infinite alternate;
}
    @-webkit-keyframes svg-text-anim {
     40% {
        stroke-dashoffset: 0;
        fill: transparent;
      }
      60% {
        stroke-dashoffset: 0;
        fill: #04caec;
      }
      100% {
        stroke-dashoffset: 0;
        fill: #04caec;
      }
      
  }
  /* Most browsers */
  @keyframes svg-text-anim {
     40% {
        stroke-dashoffset: 0;
        fill: transparent;
      }
      60% {
        stroke-dashoffset: 0;
        fill: #04caec;
      }
      100% {
        stroke-dashoffset: 0;
        fill: #04caec;
      }
      
  }